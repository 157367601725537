import React                   from 'react';
import styled                  from '@emotion/styled';
import PropTypes                    from 'prop-types';
import { Box, Heading as CHeading } from '@chakra-ui/react';

const Container = styled.div`
  text-align: center;
  hyphens: auto;
  -webkit-hyphens: none;
  margin-bottom: 2rem;
  position: relative;
  
  .int-heading-icon {
    z-index: 2;
    background-color: ${props => props.theme.base_color};
    width: 60px;
    height: 40px;
    position: absolute;
    top: ${props => props.hasSubtitle ? '-15px' : '-15px'};
    left: 0;
    right: 0;
    margin: auto;
    
    svg {
      width: 30px;
      height: 30px;
      fill: ${props => props.theme.brand_color};
    }
  }

  h1,h2,h3,h4,h5,h6 {
    font-weight: unset;
    margin-bottom: 0;
  }

  img {
    margin-bottom: 0;
  }

  &:before {
    background-color: ${props => props.color ? props.color : props.theme.brand_color};
    display: block;
    content: "";
    height: 1px;
    max-width: 500px;
    width: 90%;
    margin: 20px auto 30px;
    position: relative;
    z-index: 1;
  }
`;

const Subtitle = styled.div`
  font-weight: 300;
  font-size: 1.1rem;
  margin-top: 5px;
  text-transform: none;
`;

function Heading(props) {
  const { tag, children, subtitle, icon } = props;
  return (
    <>
      <Container id={props.id} hasSubtitle={!!subtitle}>
        <CHeading as={tag}>
          {children}
        </CHeading>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {icon && <Box className='int-heading-icon'>{icon}</Box>}
      </Container>
    </>
  )
}

Heading.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  subtitle: PropTypes.string,
  tag: PropTypes.string,
  isImage: PropTypes.bool,
};

Heading.defaultProps = {
  tag: 'h1',
  isImage: false,
};

export default Heading;
